import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Endodoncia';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          La endodoncia o tratamiento de conducto es el procedimiento que se realiza para eliminar el dolor y la
          infección de la pulpa dental, constituida por el nervio que le da la sensibilidad al diente y vasos
          sanguíneos, que está dentro del diente y recorre la raíz.
        </p>
        <p>
          La pieza dental está formada por la corona y la raíz. La corona dental es la parte visible del diente y tiene
          una capa exterior muy dura, llamada esmalte; por debajo encontramos la dentina, y por último, la pulpa dental.
          El esmalte dental funciona como un escudo impermeable, cuando este fracasa debido al proceso de caries, la
          dentina que es un tejido permeable, queda expuesta al medio bucal y suele producir dolor cuando se ingieren
          alimentos o bebidas dulces y/o frías-calientes. En este momento los microorganismos que producen la caries
          dental han invadido totalmente la dentina y han llegado al “nervio”.
        </p>
        <p>
          Cuando los microbios llegan a lesionar la pulpa el odontólogo se encarga de aislar el diente y realizar el
          tratamiento de conducto o endodoncia que consiste en perforar el diente e introducir unas limas junto con
          lavajes de un desinfectante, para eliminar el contenido de la cámara pulpar y la infección, limpiando
          (desinfectando) sus paredes por frotación con dichas limas. Luego se sellan los conductos evitando su
          reinfección. De esta manera se conserva la pieza dentaria y puede ser restaurada a corto plazo con el fin de
          recuperar su función y apariencia.
        </p>
        <h2>Preguntas frecuentes</h2>
        <h3>¿Para qué sirve una endodoncia?</h3>
        <p>
          Para salvar los dientes, evitando su extracción. De esta manera podrán seguir cumpliendo sus funciones de
          masticación y estética.
        </p>
        <h3>¿Cuánto tarda un tratamiento de conductos?</h3>
        <p>
          Actualmente los tratamientos de conductos son ejecutados en una sola cita. Hoy día se dispone del conocimiento
          y de los equipos necesarios para hacer esto posible. Tradicionalmente, estos tratamientos han sido realizados
          en varias sesiones (de 2 a 4), y si bien es posible lograr resultados adecuados en diferentes citas, la
          mayoría de casos son susceptibles de culminarse en una sola sesión, salvo algunos casos más complicados, en
          que pueden ser necesarias dos o más sesiones.
        </p>
        <h3>¿Es doloroso un tratamiento de conductos?</h3>
        <p>
          No debe serlo. En el momento de ser ejecutado el tratamiento se coloca una anestesia local que impida que el
          paciente sienta dolor.
        </p>
      </Content>
    </Layout>
  );
}
